import React, { useState, useRef, useEffect, useContext } from "react";
import api from './../../utils/api';
import moment from "moment";
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import _ from "lodash";
import { AppContext } from "../context/ContextProvider";
import { numberFormat } from './../../utils/functions';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactGA from 'react-ga4';

const BookingCalendar = ({ room_id, hotel_id, sm_calendar = false }) => {
  const { bookStartDate, bookEndDate, bookingData,
    handleSetBookStartDate,
    handleSetBookEndDate,
    handleSetBookingData,
    roomSelected,
  } = useContext(AppContext);

  const [bookings, setBookings] = useState([])

  const [customPrices, setCustomPrices] = useState([])
  const [groupPrice, setGroupPrice] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);

  const fetchPriceGroup = (roomId) => {
    api.request(`/rooms/${roomId}/price`, (data) => {
      const r = data?.room || {}
      setGroupPrice(data?.price_data || null)
    }, {})
  }

  const fetchBookingsByDate = (date, hotelId, roomId) => {
    if (!date || !roomId) return
    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])
    }, {
      hotel_id: hotelId,
      room_id: roomId,
      start_date: moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchHoliday = (hotelId, y) => {
    api.request('/holidays', (data) => {
      setHolidays(data || [])
    }, { hotel_id: hotelId, enabled: true, holiday_year: y })
  }

  const fetchCustomPriceItems = (date, roomId) => {
    api.request(`room-prices/allcriteria`, (data) => {
      setCustomPrices(data || [])
    }, {
      enabled: true,
      room_id: roomId,
      start_date: moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (!room_id || !hotel_id) return

    fetchPriceGroup(room_id)
    fetchBookingsByDate(currentStartDate, hotel_id, room_id)
    fetchHoliday(hotel_id, currentYear)
    fetchCustomPriceItems(currentStartDate, room_id)
  }, [room_id])

  useEffect(() => {
    if (!!hotel_id) {
      fetchHoliday(hotel_id, currentYear)
    }
  }, [currentYear])

  useEffect(() => {
    if (!!room_id && !!currentStartDate) {
      fetchBookingsByDate(currentStartDate, hotel_id, room_id)
      fetchCustomPriceItems(currentStartDate, room_id)
    }
  }, [currentStartDate])

  // >> price caculate
  const weekendDays = groupPrice?.weekend_days || []
  const normalPrice = !!groupPrice ? Number(groupPrice?.normal_price || 0) : Number(roomSelected?.total_price || 0)
  const peakPrice = !!groupPrice ? Number(groupPrice?.high_season_price || 0) : Number(roomSelected?.total_price || 0)

  let normalWeekendPrice = normalPrice
  let peakWeekendPrice = peakPrice

  let normalHolidayPrice = normalPrice
  let peakHolidayPrice = peakPrice

  if (!!groupPrice) {
    if (groupPrice?.additional_weekend_type === 'static') {
      normalWeekendPrice = normalPrice + Number(groupPrice?.additional_weekend_price || 0)
      peakWeekendPrice = peakPrice + Number(groupPrice?.additional_weekend_price || 0)
    } else {
      normalWeekendPrice = normalPrice + Number(normalPrice * ((groupPrice?.additional_weekend_price || 0) / 100))
      peakWeekendPrice = peakPrice + Number(peakPrice * ((groupPrice?.additional_weekend_price || 0) / 100))
    }

    if (groupPrice?.additional_holiday_type === 'static') {
      normalHolidayPrice = normalPrice + Number(groupPrice?.additional_holiday_price || 0)
      peakHolidayPrice = peakPrice + Number(groupPrice?.additional_holiday_price || 0)
    } else {
      normalHolidayPrice = normalPrice + Number(normalPrice * ((groupPrice?.additional_holiday_price || 0) / 100))
      peakHolidayPrice = peakPrice + Number(peakPrice * ((groupPrice?.additional_holiday_price || 0) / 100))
    }
  }
  // << price caculate

  function checkWeekend(date) {
    const day = (moment(date).locale("en").format('dddd').substring(0, 3)).toLocaleLowerCase()

    return weekendDays.includes(day)
  }

  function getHolidayData(date) {
    return holidays.find((h) => {
      return h.date === moment(date).format('YYYY-MM-DD') || null
    })
  }

  function checkHoliday(date) {
    return holidays.findIndex((h) => {
      return h.date === moment(date).format('YYYY-MM-DD')
    }) > -1
  }

  function checkBooked(date) {
    return bookings.findIndex((b) => {
      return moment(date).isBetween(moment(b.start_date), moment(b.end_date), null, '[]')
    }) > -1
  }

  function getBookingData(date) {
    return bookings.find((b) => {
      return moment(date).isBetween(moment(b.start_date), moment(b.end_date), null, '[]')
    }) || null
  }

  function checkCloseRoom(date) {
    return customPrices.findIndex((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !!c.block_day
    }) > -1
  }

  function getCustomPriceData(date) {
    return customPrices.find((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !c.block_day && !!c.price
    }) || null
  }

  function checkCustomPrice(date) {
    return customPrices.findIndex((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !c.block_day && !!c.price
    }) > -1
  }

  function getRoomSelectedData(date) {
    const isWeekEnd = checkWeekend(date)
    const isHoliday = checkHoliday(date)
    const customPrice = getCustomPriceData(date)

    let price = isWeekEnd ? normalWeekendPrice : normalPrice

    if (!!isHoliday) {
      price = normalHolidayPrice
    }

    if (!!customPrice) {
      price = customPrice.price
    }

    return {
      date: moment(date).format('YYYY-MM-DD'),
      price: price,
      room_id: room_id
    }
  }

  function loopDateFromStartDateToEndDate(start, end) {
    const diff = moment(end).diff(moment(start), 'days')
    if (diff <= 0) return []

    const arr = []

    for (let i = 0; i < diff; i++) {
      const d = moment(start).add(i, 'days').toDate()
      arr.push(d)
    }

    return arr
  }

  function checkRoomNotAvailable(start_date, end_date) {
    const dates = loopDateFromStartDateToEndDate(start_date, end_date) || []

    const isNotAvailable = dates.some((d) => {
      return checkCloseRoom(d) || checkBooked(d)
    })

    if (isNotAvailable) {
      return []
    } else {
      return dates.map((d) => {
        return getRoomSelectedData(d)
      }) || []
    }
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date'];
    const isPartDate = moment(date).isBefore(moment(), 'day')

    if (!isPartDate) {
      if (checkWeekend(date)) {
        classes.push('custom-weekend');
      }

      if (checkHoliday(date)) {
        classes.push('custom-holiday');
      }

      if (checkBooked(date)) {
        classes.push('custom-booked');
      }

      if (checkCloseRoom(date)) {
        classes.push('custom-close-room');
      }

      if (checkCustomPrice(date)) {
        classes.push('custom-price');
      }

      if (bookEndDate && moment(bookEndDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
        classes.push('react-calendar__tile--active');
      }
    } else {
      classes.push('part-date');
    }

    return classes.join(' ');
  }

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <div className="p-2">
        <span className="text-success">เลือกวันที่เข้าพัก โดยกดวันเช็คอิน และวันเช็คเอาท์บนปฏิทิน </span><span className="text-black">(วันที่ถูกเลือกจะแสดงแถบสีดำ)</span>
      </div>
    </Popover>
  );

  return (
    <>
      <div className={`mt-2 ${sm_calendar ? 'sm-calendar mb-2' : 'mb-4'}`} style={{ maxWidth: sm_calendar ? 350 : 500 }}>
        <div className="mb-2 d-flex title-btn">
          <span onClick={() => setCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
          {/* <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom} rootClose> */}
          <span className="ms-3 rounded" style={{ backgroundColor: '#FFCB96', color: '#000000', padding: '5px 10px' }} onClick={() => {
            setShowSuggestionModal(true)
            ReactGA.event({
              category: "Suggestion Button",
              action: `suggestion_calendar_click`,
              label: `Suggestion Calendar Click`,
            });
          }}>วิธีกดปฏิทินเพื่อจองห้องพัก
            <i className="fas fa-info-circle ps-2 text-danger" style={{ fontSize: 18 }}></i>
          </span>
          {/* </OverlayTrigger> */}
        </div>
        <Calendar
          selectRange={true}
          showDoubleView={false}
          minDate={new Date()}
          calendarType={'hebrew'}
          locale={'th-TH'}
          activeStartDate={currentStartDate}
          showNavigation={true}
          tileClassName={tileClassName}
          tileContent={({ activeStartDate, date, view }) => {
            const isWeekEnd = checkWeekend(date)
            const holidayData = getHolidayData(date)
            const customPrice = getCustomPriceData(date)

            let price = isWeekEnd ? normalWeekendPrice : normalPrice

            if (!!holidayData) {
              price = normalHolidayPrice
            }

            let priceStr = numberFormat(price, true)
            const basePrice = price

            if (!!customPrice) {
              priceStr = numberFormat(customPrice.price, true)
              price = customPrice.price
            }

            if (checkBooked(date)) {
              priceStr = 'booked'
            }

            if (bookEndDate && moment(bookEndDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
              priceStr = 'เช็คเอาท์'
            }

            if (checkCloseRoom(date)) {
              priceStr = 'ปิดบริการ'
            }

            const showBasePrice = !!customPrice && customPrice.price < basePrice

            return (view === 'month') ?
              (
                (!!holidayData) ?
                  <OverlayTrigger delay={{ show: 300, hide: 0 }} overlay={<Tooltip>{holidayData.title_th}</Tooltip>} placement="top">
                    <div className="price-container">
                      {showBasePrice && <small className="normal-price-small">{numberFormat(basePrice, true)}</small>}
                      <p className={`booking-calendar-price`}>{priceStr}
                        <i className="fal fa-calendar ms-1"></i>
                      </p>
                    </div>
                  </OverlayTrigger>
                  :
                  <div className="price-container">
                    {showBasePrice && <small className="normal-price-small">{numberFormat(basePrice, true)}</small>}
                    <p className={`booking-calendar-price`}>{priceStr}</p>
                  </div>
              )
              : null
          }}
          // tileDisabled={({ activeStartDate, date, view }) => {
          //   return checkCloseRoom(date) && view === 'month' ? true : checkBooked(date)
          // }}
          value={[bookStartDate, bookEndDate]}
          onChange={(e) => {
            if (Array.isArray(e) && e.length === 2) {
              const selectedList = checkRoomNotAvailable(e[0], e[1]) || []
              if (!selectedList.length) {
                handleSetBookStartDate(null)
                handleSetBookEndDate(null)
                handleSetBookingData([])
                return
              }
              handleSetBookStartDate(e[0])
              handleSetBookEndDate(e[1])
              handleSetBookingData(selectedList)
            }
          }}
          onClickDay={(d) => {
            const startDateSelected = d
            const nextDay = moment(d).add(1, 'days').toDate()

            const selectedList = checkRoomNotAvailable(startDateSelected, nextDay) || []
            if (!selectedList.length) {
              handleSetBookStartDate(null)
              handleSetBookEndDate(null)
              handleSetBookingData([])
              return
            }
            handleSetBookStartDate(startDateSelected)
            handleSetBookEndDate(nextDay)
            handleSetBookingData(selectedList)
          }}
          onActiveStartDateChange={(s) => {
            if (s?.activeStartDate) {
              setCurrentStartDate(s.activeStartDate)
              setCurrentYear(moment(s.activeStartDate).format('YYYY'))
            }
          }}
        />
      </div>
      <Modal className="fade modal-close-out" show={showSuggestionModal}>
        <Modal.Body className="d-flex flex-column p-0">
          <img src={"/assets/img/booking/calendar_suggestion.png"} alt="img" />
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button variant={"secondary"} onClick={() => setShowSuggestionModal(false)}>ปิด</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BookingCalendar;
