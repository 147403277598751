import React from 'react';
import _ from 'lodash'
import { Button, Modal } from 'react-bootstrap';

export default function ConfirmModal({ show = false, onCancel = () => { }, onConfirm = () => { }, title = null, description = null, confirmDisabled = false }) {
  return (
    <Modal className="fade modal-close-out" show={show}>
      <Modal.Header>
        <Modal.Title>{title || 'Are you sure ?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <p>
          <span>{description || 'Confirm'} </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>No</Button>
        <Button disabled={confirmDisabled} variant="outline-primary" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
