import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api, { WEB_BASE_URL } from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/th'
import _, { set } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ShowBookingInfo from "./ShowBookingInfo";
import Switch from "react-switch";
import ConfirmModal from "../common/Modals/ConfirmModal";

export default function BookingOverview() {
  const { hotel_id } = useParams();
  const { setDummyToken, getDummyToken } = useContext(AppContext);

  const [hotel, setHotel] = useState({})
  const [rooms, setRooms] = useState([])
  const [bookings, setBookings] = useState([])

  const [mainCurrentStartDate, setMainCurrentStartDate] = useState(new Date());
  const [blockdays, setBlockdays] = useState([]);

  const [showBookingsInfo, setShowBookingsInfo] = useState(null); //null || date
  const [bookingsForShow, setBookingsForShow] = useState([]);
  const [blockdaysForShow, setBlockdaysForShow] = useState([]);
  const [closeAllDataForShow, setCloseAllDataForShow] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [_tokenKey, setTokenKey] = useState(null);
  const [bookingIdForShow, setBookingIdForShow] = useState(null);

  const [convertCalendar, setConvertCalendar] = useState(false);

  const [customTentConfigs, setCustomTentConfigs] = useState([]);
  const [showCheckTimeConfirmModal, setShowCheckTimeConfirmModal] = useState(null); // { type: 'check-in', booking: {} } || null

  const a = moment(new Date(moment(endDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(startDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') || 0

  const checkToken = getDummyToken()

  const verifyToken = () => {
    if (!checkToken || !checkToken.length || !hotel_id) {
      return false
    }

    const hotelIdStr = hotel_id.replace(/-/g, '')
    if (checkToken !== `${hotelIdStr}gVi3NKTzFwBn`) {
      return false
    }

    return true
  }

  const fetchHotel = () => {
    if (!hotel_id) {
      return
    }

    api.request(`hotels/${hotel_id}`, (data) => {
      if (data) {
        setHotel(data)
        fetchRooms(data.id)
      }
    }, {})
  }

  const fetchRooms = (_hotelId) => {
    api.request(`rooms/all`, (res) => {
      setRooms(res || [])
    }, {
      enabled: true,
      hotel_id: _hotelId
    })
  }

  const fetchฺBookingFromDate = (currentDate, _dateForRefresh = null) => {
    if (!currentDate) {
      setBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])

      if (!!_dateForRefresh && data?.length > 0) {
        setBookingsForShow(_getBookingsInfo(data, _dateForRefresh))
        setCloseAllDataForShow(_getCloseAllData(data, _dateForRefresh))
      }
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchฺBlockdayFromDate = (currentDate) => {
    if (!currentDate) {
      setBlockdays([])
      return
    }

    api.request('bookings/all-check-room-blockday', (data) => {
      if (data && data.length) {
        setBlockdays(data.map((item) => {
          return {
            date: item.date,
            room_id: item.room_id,
            room_name: item.room?.name_th,
            note: item.note,
          }
        }) || [])
      } else {
        setBlockdays([])
      }
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchCustomTentConfig = (currentDate) => {
    if (!!hotel?.id && !hotel.allow_walkin) {
      setCustomTentConfigs([])
      return
    }

    api.request(`custom-tent/allcriteria`, (data) => {
      setCustomTentConfigs(data || [])
    }, {
      enabled: true,
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (!hotel_id) {
      alert('ลิงค์ไม่ถูกต้อง !')
      return
    }

    if (!verifyToken()) {
      alert('Key ไม่ถูกต้อง !')
      return
    }

    fetchHotel()
    fetchฺBookingFromDate(mainCurrentStartDate)
    fetchฺBlockdayFromDate(mainCurrentStartDate)
    fetchCustomTentConfig(mainCurrentStartDate)
  }, [checkToken])

  useEffect(() => {
    if (!hotel_id) {
      return
    }

    if (!!mainCurrentStartDate) {
      fetchฺBookingFromDate(mainCurrentStartDate)
      fetchฺBlockdayFromDate(mainCurrentStartDate)
      fetchCustomTentConfig(mainCurrentStartDate)
    }
  }, [mainCurrentStartDate])

  const checkCloseAll = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0
  }

  const getCloseAllData = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0 ? hasClose[0] : null
  }

  const _getCloseAllData = (_bookings = [], date) => {
    const hasClose = _bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0 ? hasClose[0] : null
  }

  const checkBooking = (date) => {
    const hasBooking = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    const carBooking = hasBooking.filter((item) => {
      return !!item.room_id && !item.is_walkin
    })

    const walkinBooking = hasBooking.filter((item) => { return item.is_walkin })
    const walkinPerson = walkinBooking.map((b) => { return Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) }).reduce((a, b) => a + b, 0)

    return {
      all: hasBooking.length || 0,
      car: carBooking.length || 0,
      carNames: carBooking.length > 0 ? ((carBooking.map((b) => { return b.room?.name_th }) || []).join(', ')) : '',
      walkin: walkinBooking.length || 0,
      walkin_person: walkinPerson || 0
    }
  }

  const getBookingsInfo = (date) => {
    const hasBookings = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    return hasBookings.sort((a, b) => {
      return a.is_walkin ? 1 : -1
    })
  }

  const _getBookingsInfo = (_bookings = [], date) => {
    const hasBookings = _bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    return hasBookings.sort((a, b) => {
      return a.is_walkin ? 1 : -1
    })
  }

  const getBlockdayInfo = (date) => {
    return blockdays.filter((item) => {
      return date === item.date
    }) || []
  }

  const checkTokenKeyInput = () => {
    if (!hotel_id || !_tokenKey || !_tokenKey.length) {
      return
    }

    const hotelIdStr = hotel_id.replace(/-/g, '')

    if (_tokenKey !== `${hotelIdStr}gVi3NKTzFwBn`) {
      alert('Key ไม่ถูกต้อง !')
      return
    }

    setDummyToken(_tokenKey)
    window.location.reload()
  }

  const checkWakingGuestQty = (date, _bookingPerson = 0) => {
    if (!hotel?.allow_walkin) {
      return 0
    }

    const isCloseWalking = customTentConfigs.find((x) => moment(x.date).isSame(moment(date), 'day') && x.qty === 0) || null
    if (isCloseWalking) {
      return 0
    }

    let limit = Number(hotel?.walkin_limit || 0)
    const _customLimit = customTentConfigs.find((x) => moment(x.date).isSame(moment(date), 'day') && x.qty > 1) || null

    if (_customLimit) {
      limit = Number(_customLimit.qty)
    }

    if (_bookingPerson >= limit) {
      return 0
    }

    return _bookingPerson > 0 ? (limit - Number(_bookingPerson || 0)) : limit
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date', 'px-1'];
    const isPartDate = moment(date).isBefore(moment(), 'day')
    const dateStr = moment(date).format('YYYY-MM-DD')

    if (moment(date).isoWeekday() === 5 || moment(date).isoWeekday() === 6) {
      classes.push('custom-weekend');
    }

    if (checkCloseAll(dateStr)) {
      classes.push('custom-close-all');
    }

    if (!convertCalendar) {


      const checkBookingData = checkBooking(dateStr)
      if (checkBookingData.all > 0) {
        classes.push('custom-has-booking');
      }

      const checkBlockdayData = getBlockdayInfo(dateStr)
      if (checkBlockdayData.length > 0) {
        classes.push('custom-close-room');
      }
    }

    if (isPartDate) {
      classes.push('custom-part-date');
    }

    return classes.join(' ');
  }

  if (!hotel_id || !checkToken || !checkToken.length || !verifyToken()) {
    return (
      <div className="p-4">
        <div className="text-start" style={{ width: 250 }}>
          <form className="form-group" onSubmit={(e) => { e.preventDefault(); checkTokenKeyInput() }}>
            <input type="text" className="form-control" placeholder="Enter Key" value={_tokenKey || ''} onChange={(e) => setTokenKey(e.target.value)} />
            <button disabled={!_tokenKey || !_tokenKey.length} type="submit" className="btn btn-sm btn-primary mt-2">Submit</button>
          </form>
        </div>
      </div>
    )
  }

  const walkinPersonForShow = (bookingsForShow || []).filter((b) => { return b.is_walkin })?.map((b) => { return Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) }).reduce((a, b) => a + b, 0)

  const onClickDay = (date) => {
    const dateStr = moment(date).format('YYYY-MM-DD')

    const checkCloseAllData = checkCloseAll(dateStr)
    if (checkCloseAllData) {
      setCloseAllDataForShow(getCloseAllData(dateStr))
      setShowBookingsInfo(dateStr)
      return
    }

    const checkBookingData = checkBooking(dateStr)
    if (checkBookingData.all > 0) {
      setBookingsForShow(getBookingsInfo(dateStr))
    }

    const checkBlockdayData = getBlockdayInfo(dateStr)
    setBlockdaysForShow(checkBlockdayData || [])

    if (checkBookingData.all > 0 || checkBlockdayData.length > 0) {
      setShowBookingsInfo(dateStr)
    }
  }

  return (
    <>
      <div className="text-center">
        <div className={`px-2 px-md-5 py-3`}>
          <div className="text-start pb-2">
            <h5 className="text-black">BOOKING OVERVIEW : {hotel?.name_th}</h5>
          </div>
          <div className="booking-form-input">
            <div className="mt-2">
              <div className={`mt-2 mb-4`} style={{ maxWidth: 500 }}>
                <div className="mb-2 d-flex title-btn align-items-center">
                  <span onClick={() => setMainCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                  <div className="d-flex align-items-center ms-3">
                    <Switch onChange={(e) => {
                      setConvertCalendar(e)
                    }} checked={convertCalendar} offColor={'#B0B0B0'} onColor={'#008b8b'} />
                    <label className="ms-2 text-black">ดูจำนวนห้องว่าง</label>
                  </div>
                </div>
                {!convertCalendar && <Calendar
                  showDoubleView={false}
                  // minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={mainCurrentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    let priceStr = ''
                    const dateStr = moment(date).format('YYYY-MM-DD')

                    const checkBlockdayData = getBlockdayInfo(dateStr)
                    if (checkBlockdayData.length > 0) {
                      priceStr = 'Block Day'
                    }

                    if (checkCloseAll(dateStr)) {
                      priceStr = 'เหมาลาน'
                    }

                    const checkBookingData = checkBooking(dateStr)

                    if (checkBookingData.all > 0) {
                      priceStr = checkBookingData.all + ' การจอง'
                    }

                    return (view === 'month') ?
                      (
                        <div className="price-container">
                          {checkBookingData.all === 0 && <p className={`booking-calendar-price`}>{priceStr}</p>}
                          {checkBookingData.all > 0 && <p className={`booking-calendar-price`}>
                            {checkBookingData.car > 0 && <div style={{ lineHeight: 'normal' }} className="mt-1"><i className="fal fa-house-user"></i> {checkBookingData.car}</div>}
                            {checkBookingData.walkin > 0 && <div style={{ lineHeight: 'normal' }} className="mt-1">
                              <span><i className="fal fa-campground"></i> {checkBookingData.walkin}</span>
                              <span><i className="fal fa-user ps-1"></i> {checkBookingData.walkin_person}</span>
                            </div>}
                          </p>
                          }
                        </div>
                      )
                      : null
                  }}
                  value={null}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setMainCurrentStartDate(s.activeStartDate)
                    }
                  }}
                  onClickDay={onClickDay}
                />}
                {!!convertCalendar && <Calendar
                  showDoubleView={false}
                  minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={mainCurrentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    let priceStr = ''
                    const dateStr = moment(date).format('YYYY-MM-DD')
                    const isToday = moment(date).isSame(moment(), 'day')
                    const isPartDate = moment(date).isBefore(moment(), 'day')

                    const checkBlockdayData = getBlockdayInfo(dateStr)
                    if (checkBlockdayData.length > 0) {
                      priceStr = 'Block Day'
                    }

                    const isCloseAll = checkCloseAll(dateStr)
                    if (isCloseAll) {
                      priceStr = 'เหมาลาน'
                    }

                    const checkBookingData = checkBooking(dateStr)

                    const roomDiff = (rooms.length || 0) - (checkBookingData.car || 0) - (checkBlockdayData.length || 0)
                    const availableRoom = roomDiff > 0 ? roomDiff : 0
                    // const availableWalkinGuest = (hotel?.walkin_limit || 0) - (checkBookingData.walkin_person || 0)
                    const availableWalkinGuest = checkWakingGuestQty(date, (checkBookingData.walkin_person || 0))

                    return (view === 'month' && !isPartDate) ?
                      (
                        <div className="price-container">
                          {isCloseAll && <p className={`booking-calendar-price`}>{priceStr}</p>}
                          {!isCloseAll && <p className={`booking-calendar-price`}>
                            {availableRoom > 0 && <span className={`${isToday ? 'text-white' : 'text-success'}`}><i className="fal fa-house-user"></i> {availableRoom}</span>}
                            {(!!hotel?.allow_walkin && availableRoom > 0 && availableWalkinGuest > 0) && <span className="ps-1"></span>}
                            {(!!hotel?.allow_walkin && availableWalkinGuest > 0) && <span className={`${isToday ? 'text-white' : 'text-info'}`}><i className="fal fa-campground"></i> {availableWalkinGuest}</span>}
                          </p>}
                        </div>
                      )
                      : null
                  }}
                  value={null}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setMainCurrentStartDate(s.activeStartDate)
                    }
                  }}
                  onClickDay={onClickDay}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={!!showBookingsInfo} onHide={() => {
        setShowBookingsInfo(null)
        setBookingsForShow([])
        setBlockdaysForShow([])
        setCloseAllDataForShow(null)
      }} backdrop="static">
        <Modal.Header closeButton className="p-3">
          <Modal.Title>รายละเอียดการจองห้องพัก ({moment(showBookingsInfo).format('DD/MM/YYYY')})</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1 booking-status">
          <div className="text-start p-0">
            {!closeAllDataForShow && <table className="table table-striped">
              <tbody>
                {bookingsForShow?.length > 0 && bookingsForShow.map((b, i) => {
                  const icon = b.is_walkin ? 'fal fa-campground' : 'fal fa-house-user'
                  const walkinPerson = b.is_walkin ? Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) : 0

                  const _checkoutTime = b.checkout_time || b.complete_time

                  return (
                    <tr key={`booking-info-${i}`}>
                      <td>
                        <div className="d-flex align-items-center" onClick={() => setBookingIdForShow(b.id)}>
                          <i className={`${icon}`}></i>
                          <div className={`ms-2`}>
                            {!b.is_walkin && <div>{b.room?.name_th}</div>}
                            {!!b.is_walkin && <div>{numberFormat(walkinPerson, true)} ท่าน</div>}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>{b.contact_name}</div>
                        {!b.checkin_time && !_checkoutTime && b?.state !== 'complete' && <div>
                          <Button className="py-0" variant="outline-success" size="sm" onClick={() => setShowCheckTimeConfirmModal({
                            type: 'checkin',
                            booking: b
                          })}><i className="fal fa-user-check me-1"></i><small>CHECK - IN</small></Button>
                        </div>}
                        {!!b.checkin_time && <div>
                          <small className="text-success"><i className="fal fa-user-check"></i> {moment(b.checkin_time).format('DD/MM/YYYY HH:mm')}</small>
                        </div>}
                        {(!!_checkoutTime) && <div>
                          <small className="text-danger"><i className="fal fa-sign-out-alt"></i> {moment(_checkoutTime).format('DD/MM/YYYY HH:mm')}</small>
                        </div>}
                        {!!b.checkin_time && !_checkoutTime && b?.state !== 'complete' && <div>
                          <Button className="py-0" variant="outline-danger" size="sm" onClick={() => setShowCheckTimeConfirmModal({
                            type: 'checkout',
                            booking: b
                          })}><i className="fal fa-sign-out-alt me-1"></i><small>CHECK - OUT</small></Button>
                        </div>}
                      </td>
                      <td>{b.contact_tel_1}</td>
                    </tr>
                  )
                })}
                {walkinPersonForShow > 0 && <tr>
                  <td colSpan={3}>
                    <div className="d-flex align-items-center">
                      <i className={'fal fa-campground'}></i>
                      <div className="ms-2">รวมกางเต็นท์ (ผู้ใหญ่ + เด็ก 6-12 ปี) : {numberFormat(walkinPersonForShow, true)} ท่าน</div>
                    </div>
                  </td>
                </tr>}
                {blockdaysForShow?.length > 0 && blockdaysForShow.map((b, i) => {
                  return (
                    <tr key={`booking-info-${i}`}>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className={"text-danger fal fa-times-circle"}></i>
                          <div className="ms-2">
                            <div className="text-danger ">{b.room_name}</div>
                          </div>
                        </div>
                      </td>
                      <td colSpan={2}><small className="text-muted">{b.note}</small> </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>}
            {!!closeAllDataForShow && <div className="p-3">
              <p className="text-primary mb-2">ลูกค้าเหมาลาน</p>
              <ul>
                <li><span className="text-black">ชื่อผู้ติดต่อ : </span> {closeAllDataForShow.contact_name}</li>
                <li><span className="text-black">หมายเลขโทรศัพท์ : </span> {closeAllDataForShow.contact_tel_1}</li>
                <li><span className="text-black">หมายเหตุ : </span> {closeAllDataForShow.booking_note || '-'}</li>
              </ul>
            </div>}
          </div>
        </Modal.Body>
      </Modal>
      <ShowBookingInfo show={!!bookingIdForShow} onHide={() => setBookingIdForShow(null)} booking_id={bookingIdForShow} is_admin={true} onRefresh={() => fetchฺBookingFromDate(mainCurrentStartDate, showBookingsInfo)} />
      <ConfirmModal
        show={!!showCheckTimeConfirmModal?.type}
        title={'แน่ใจหรือไม่ ?'}
        description={`บันทึกเวลาลูกค้าเช็ค${showCheckTimeConfirmModal?.type === 'checkin' ? 'อิน' : 'เอาท์'} ?`}
        onCancel={() => {
          setShowCheckTimeConfirmModal(null)
        }}
        onConfirm={() => {
          if (!showCheckTimeConfirmModal?.booking?.id) {
            return
          }

          api.request(`bookings/checktime/${showCheckTimeConfirmModal?.booking?.is_close_zone ? showCheckTimeConfirmModal?.booking?.main_id : showCheckTimeConfirmModal?.booking?.id}/${showCheckTimeConfirmModal?.type}/${showCheckTimeConfirmModal?.booking?.is_walkin ? 'walkin' : (showCheckTimeConfirmModal?.booking?.is_close_zone ? 'closezone' : 'room')}`, (res, success) => {
            if (!success) {
              return
            } else {
              fetchฺBookingFromDate(mainCurrentStartDate, showBookingsInfo)
              setShowCheckTimeConfirmModal(null)
            }
          }, {})
        }}
      />
      <OnceFooter />
    </>
  );
}
